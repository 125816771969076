/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import useIsInViewport from 'use-is-in-viewport'

import Image from "./image"
import ButtonLink from "./button-link"

const Service = ({ title, slug, illustration, excerpt }) => {

  const [isInViewport, targetRef] = useIsInViewport()

  return (
    <S.Article className="shadow-md rounded-lg cursor-pointer border-1 border-gray-300 hover:border-blue-400 bg-white py-10 mx-4" sx={{ mt: 3, px: 3, flexBasis: ["100%", "100%", "45%", "30%"] }}>
      <div  ref={targetRef} className={isInViewport ? 'inv-visible' : 'inv-hidden'}>
        <Image className="mx-auto mb-8" image={illustration} alt={title} sx={{ width: '80%', maxWidth: '150px' }} />
        <Styled.h4 sx={{ color: 'greenblue' }} className="mt-4 mb-4 text-center">{title}</Styled.h4>
        <Styled.p sx={{ color: 'greenblue' }}  className="mt-4 leading-9" sx={{minHeight: '144px' }}>{excerpt}</Styled.p>
        <a className="mt-6 w-full transition-all shadow-md inline-block tracking-widest ease-linear align-middle visible whitespace-nowrap justify-center width-auto py-0 px-12 bg-green-500 text-center text-base bg py-4 font-base text-white focus:outline-none hover:opacity-90 rounded hover:bg-green-400" href="#contact">Contact Us</a>
      </div>
    </S.Article>
  )
}

export default Service

export const fragment = graphql`
  fragment ServiceSectionFields on Service {
    id
    slug
    title
    excerpt
    illustration {
      ...ImageFragment
    }
  }
`

const S = {}

S.Article = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  box-sizing: border-box;
  max-width: ${({ theme }) => theme.sizes.container * 0.6}px;
`
