/** @jsx jsx */
import { jsx, Styled, Link } from "theme-ui"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Image from "../image"
import {
  Container,
} from "theme-ui"

const Hero = ({ greeting, name, subtitle, image }) => {

  const backgroundStyles = css`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 200 200'%3E%3Cpolygon fill='%2377acff' fill-opacity='0.09' points='100 0 0 100 100 100 100 200 200 100 200 0'/%3E%3C/svg%3E");

  `

  const otherBg = css`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' opacity='0.5' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23ffffff' stroke-width='61.1' stroke-opacity='0.12' fill-opacity='0.2' %3E%3Ccircle  fill-opacity='0.1' fill='%2377acff' cx='0' cy='0' r='1800'/%3E%3Ccircle fill-opacity='0.1' fill='%2381b1ff' cx='0' cy='0' r='1700'/%3E%3Ccircle fill-opacity='0.2' fill='%238bb5ff' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%2394baff' fill-opacity='0.1' cx='0' cy='0' r='1500'/%3E%3Ccircle fill-opacity='0.1' fill='%239dbfff' cx='0' cy='0' r='1400'/%3E%3Ccircle fill-opacity='0.2' fill='%23a5c3ff' cx='0' cy='0' r='1300'/%3E%3Ccircle fill-opacity='0.1' fill='%23aec8ff' cx='0' cy='0' r='1200'/%3E%3Ccircle fill-opacity='0.2' fill='%23b6cdff' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23bdd2ff' fill-opacity='0.2' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23c5d7ff' fill-opacity='0.2' cx='0' cy='0' r='900'/%3E%3Ccircle fill-opacity='0.2' fill='%23cddcff' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23d4e1ff' fill-opacity='0.2' cx='0' cy='0' r='700'/%3E%3Ccircle fill-opacity='0.2' fill='%23dce6ff' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%23e3ebff' fill-opacity='0.2' cx='0' cy='0' r='500'/%3E%3Ccircle fill-opacity='0.2' fill='%23eaf0ff' cx='0' cy='0' r='400'/%3E%3Ccircle fill-opacity='0.1' fill='%23f1f5ff' cx='0' cy='0' r='300'/%3E%3Ccircle fill-opacity='0.2' fill='%23f8faff' cx='0' cy='0' r='200'/%3E%3Ccircle fill-opacity='0.2' fill='%23ffffff' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
  `

  return (
    <div className="hero-gradient float-left flow-root w-full">
      <div css={otherBg.styles} className="float-left w-full px-4 pt-40 pb-24 lg:pb-30 lg:pt-50">
        <Container>
          <S.Section>
            <S.ImageContainer className="xl:pl-8 mt-10">
              <Image className="mt-10 lg:mt-0 rounded-lg shadow-xl fade-in" image={image} alt={image.imageAltText} />
            </S.ImageContainer>
            <S.TextContainer>
              <Styled.h1 sx={{ variant: "textStyles.display", color: 'greenblue' }} className="lg:mr-4 lg:text-5xl">
                {greeting} <S.Name>{name}</S.Name>
              </Styled.h1>
              <p className="text-xl mt-4 mb-8 lg:mr-24 leading-9" sx={{ color: 'greenblue' }}>One-to-one sessions for Headteachers. A chance to discuss your work and share your worries with a fellow professional of 30 years experience.</p>
              <div className="flex flex-wrap">
                <a className="transition-all mr-4 shadow-md inline-block tracking-widest ease-linear align-middle visible whitespace-nowrap justify-center width-auto py-0 px-12 bg-blue-500 text-center text-base bg py-4 font-base text-white focus:outline-none hover:opacity-90 rounded hover:bg-blue-400" href="#about">About Us</a>
                <a className="sm:mt-0 transition-all shadow-md inline-block tracking-widest ease-linear align-middle visible whitespace-nowrap justify-center width-auto py-0 px-12 bg-green-500 text-center text-base bg py-4 font-base text-white focus:outline-none hover:opacity-90 rounded hover:bg-green-400" href="#contact">Contact Us</a>
              </div>
            </S.TextContainer>
          </S.Section>
        </Container>
      </div>
    </div>
  )
}

export default Hero

export const fragment = graphql`
  fragment HeroFields on HeroYaml {
    id
    greeting
    name
    subtitle
    image {
      ...ImageFragment
    }
    imageAltText
  }
`

const S = {}

S.Section = styled.section`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-areas:
    "text"
    "image";
  grid-gap: ${props => props.theme.space[1]}px;
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 50%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text image";
  }
`

S.TextContainer = styled.div`
  grid-area: text;
  align-self: center;
`

S.Name = styled.span`
  border-bottom: 4px solid #639;
`

S.ImageContainer = styled.div`
  grid-area: image;
`
