/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import useIsInViewport from 'use-is-in-viewport'

import Phil from './phil.jpg'
import Img from 'gatsby-image'

import { Link, graphql, useStaticQuery } from "gatsby"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      phil: file(name: {eq: "phil-hallman-desk"}) {
        childImageSharp {
            fixed(width: 268, height: 356) {
                ...GatsbyImageSharpFixed
            }
        }        
        id
        publicURL
      } 
      dbs: file(name: {eq: "dbschecked"}) {
        childImageSharp {
            fixed(width: 120, height: 80, quality: 90) {
                ...GatsbyImageSharpFixed
            }
        }
        id
        publicURL
      }            
    }
  `)

    const [isInViewport, targetRef] = useIsInViewport()

    return (
        <div ref={targetRef} className={isInViewport ? 'inv-visible' : 'inv-hidden'}>
            <h2 sx={{ color: 'greenblue' }} className="text-center text-3xl">About Heads' Space</h2>
            <div className="flex items-center justify-between w-full mt-10">
                <div className="w-full rounded h-full bg-white">
                    <div className="flex flex-col lg:flex-row w-full overflow-hidden">
                        <div className="w-full lg:w-2/3 rounded-l px-4">
                            <p sx={{ color: 'greenblue' }} className="text-lg lg:mr-2 leading-9 mb-6">I have recently retired after serving 30 years as a Primary School Headteacher. Throughout my time I was a highly motivated, hardworking leader who always strove to achieve distributed leadership through positive relationships, high expectations and by building initiative capability. All of this with a good sense of humour and a love of life, family and friends.</p>
                            <p sx={{ color: 'greenblue' }} className="text-lg lg:mr-2 leading-9">My new business Heads' Space Ltd is aimed purely at Heads and Management teams to provide completely independent, confidential support for mental health and well-being. As a long serving Head I realise how important it is have someone to whom you can offload to who actually understands and appreciates the stresses a Head can be under. When I first became a Head, this often came through the Local Authority, fellow Heads or outside agencies such as Diocese etc; sadly this is no longer the case and the stresses are greater now than at anytime in the past. Having a chance to offload your personal and professional concerns and worries, which is what I'm offering, enables you to become more effective in your job and in your life.</p>
                        </div>
                        <div className="w-full text-center lg:w-1/3 lg:rounded-r px-4 mt-8 lg:mt-0">
                            <Img sx={{ maxWidth: '80%' }} fixed={data.phil.childImageSharp.fixed} className="rounded lg:pl-12 mx-auto mb-0 rounded" /> <br />
                            <Img objectFit="contain" fixed={data.dbs.childImageSharp.fixed} className="rounded mx-auto block clear-both mb-0 rounded" /> 
                        </div>
                    </div>   
                    <div className=" flex flex-wrap justify-between items-center">
                        <a className=" mx-auto transition-all shadow-md inline-block mt-10 tracking-widest ease-linear align-middle visible whitespace-nowrap justify-center width-auto py-0 px-12 bg-green-500 text-center text-base bg py-4 font-base text-white focus:outline-none hover:opacity-90 rounded hover:bg-green-400" href="https://www.headteacher-update.com/best-practice-article/leadership-profile-a-28-year-learning-curve/216250/" target="_blank">About Me</a>
                    </div>
                </div>
            </div>                                                      
        </div>
    );
}
export default AboutUs;
