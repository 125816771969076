/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import validator from 'email-validator'
import Link from "gatsby-link"
import styled from "@emotion/styled"
import { ContactFormContainer } from '../componentStyle'
import tw from 'tailwind.macro';

import ButtonLink from "../button-link"

export class ContactForm extends React.Component {
  render() {
    const showEmailError = this.emailNode && !this.props.emailIsValid
    return (
        <>
            <div id="contact" className="mx-auto py-20 lg:py-32">
                <div className="lg:flex">
                    <div sx={{ bg: '#d7d7ff2b' }} className="xl:w-2/5 lg:w-2/5 bg-indigo-100 py-16 xl:rounded-bl rounded-tl rounded-tr xl:rounded-tr-none">
                        <div className="xl:w-5/6 xl:px-0 px-8 mx-auto">
                            <h3 sx={{ color: 'greenblue' }} className="xl:text-4xl mb-3 text-3xl pb-4 font-bold">Get in touch</h3>
                            <p sx={{ color: 'greenblue' }} className="text-xl pb-8 leading-relaxed font-normal lg:pr-4">Got a question about us? Are you interested in partnering with us? Have some suggestions or just want to say Hi? Just contact us. We are here to asset you.</p>
                            <div className="flex pb-4 items-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone-call" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1f4871" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <path d="M4 4h5l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v5a1 1 0 0 1 -1 1a16 16 0 0 1 -16 -16a1 1 0 0 1 1 -1" />
                                        <path d="M15 7a2 2 0 0 1 2 2" />
                                        <path d="M15 3a6 6 0 0 1 6 6" />
                                    </svg>
                                </div>
                                <a sx={{ color: 'greenblue' }} href="tel:+447498788317" className="pl-4 text-base">07498 788317</a>
                            </div>
                            <div className="flex items-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1f4871" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <rect x={3} y={5} width={18} height={14} rx={2} />
                                        <polyline points="3 7 12 13 21 7" />
                                    </svg>
                                </div>
                                <a sx={{ color: 'greenblue' }} href="mailto:hello@heads-space.com" className="pl-4 text-base">hello@heads-space.com</a>
                            </div>
                            <p sx={{ color: 'greenblue' }} className="text-lg pt-10 tracking-wide">
                                25 The Cobbles,<br />
                                Delamere Park,<br />
                                Cuddington,<br />
                                Cheshire,<br />
                                CW8 2XH
                            </p>
                        </div>
                    </div>
                    <div className="xl:w-3/5 lg:w-3/5">
                        <form
                            onChange={this.props.onChange}
                            onSubmit={this.props.onSubmit}
                            className='shadow-md mb-0'
                            accept-charset="utf-8" 
                            name="survey-form" 
                            id="myForm"
                            action="https://formspree.io/f/xknknwbe"
                            method="POST"      
                            className="w-full px-6 max-w-lg"         
                        >
                        <fieldset id="fs-frm-inputs">
                            <h2 className="pb-6 mt-12">Contact Us</h2>
                            <label className="mb-2 block" for="full-name">Full Name</label>
                            <input className="block focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" type="text" name="name" id="full-name" placeholder="First and Last" required="" />
                            <label className="mt-4 mb-2 block" for="email-address">Email Address</label>
                            <input className="block focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" type="email" name="_replyto" id="email-address" placeholder="email@domain.tld" required="" />
                            <fieldset id="fs-frm-selects">
                              <label className="mt-6 mb-2 block" for="service">What service are you interested in?</label>
                              <select className="block focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" name="service" id="service" required="">
                                <option value="Choose" selected="" disabled="">Choose</option>
                                <option value="Single Half-Day Meeting">Single Half-Day Meeting</option>
                                <option value="Single 2-hour meeting per term">Single 2-hour meeting per term</option>
                                <option value="2 hour meeting per half-term">2 hour meeting per half-term</option>
                              </select>
                            </fieldset>
                            <label className="mt-6 mb-2 block" for="message">Additional Comments</label>
                            <textarea className="block p-4 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-30 flex items-center pl-3 text-sm border-gray-300 rounded border" rows="3" name="message" id="message" placeholder="Enter a message..." required=""></textarea>
                            <input type="hidden" name="_subject" id="email-subject" value="Heads`Space Form Submission" />
                        </fieldset>
                        <input className="mb-10 w-full mx-auto transition-all shadow-md inline-block tracking-widest ease-linear align-middle visible whitespace-nowrap justify-center width-auto py-0 px-12 bg-green-500 text-center text-base bg py-4 font-base text-white mt-6 focus:outline-none hover:opacity-90 rounded hover:bg-green-400"  type="submit" value="Send Message" />
                      </form>
                    </div>
                </div>
            </div>
      </>
    )
  }
}

export default ContactForm

const S = {}

S.Button = styled.button`
    ${tw`
        block mx-4 mt-4 mx-auto md:inline-block w-full md:max-w-md text-white py-3 px-3 text-center uppercase
    `}
    width: -webkit-fill-available;
    background-color: #fff;
    color: black;
    font-family: 'Inter','Helvetica Neue','Helvetica','Arial',sans-serif;
    border-color: #7b7b7b;      
`