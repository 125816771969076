/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/sections/hero"
import Blog from "../components/sections/blog-section"
import Portfolio from "../components/sections/portfolio-section"
import References from "../components/sections/reference-section"
import Services from "../components/sections/service-section"
import Pricing from "../components/sections/pricing-section"
import ContactForm from "../components/sections/contact-section"
import About from "../components/sections/about-section"

import { css } from "@emotion/core"

import {
  Container,
} from "theme-ui"

const FrontpageTemplate = ({ data, pageContext }) => {
  const { hero, posts, portfolio, references, services } = data
  const {
    basePaths: { blogBasePath, portfolioBasePath, referencesBasePath },
  } = pageContext

  const otherBg = css`
background-color: #f0f1ff;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23ffffff' stroke-width='15.1' stroke-opacity='0.55' %3E%3Ccircle fill='%23f0f1ff' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%23f1f2ff' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23f2f3ff' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%23f3f3ff' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%23f4f4ff' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%23f4f5ff' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23f5f6ff' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%23f6f7ff' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23f7f8ff' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23f8f8ff' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%23f9f9ff' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23fafaff' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23fbfbff' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%23fbfcff' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23fcfdff' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%23fdfdff' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%23fefeff' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23ffffff' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
  `

  return (
    <Layout>
      <Hero {...hero} />
      <div id="about" className="flow-root py-20 lg:py-32 clear-both">
        <Container>
          <About />
        </Container>
      </div>          
      <div id="services" className="hero-gradient flow-root clear-both">
        <div css={otherBg.styles} className="float-left w-full px-4">
          <Container>
            <Services services={services.nodes} />
          </Container>
        </div>
      </div>
      <div id="references" className="flow-root">
       <References
          title="References"
          references={references.nodes}
          basePath={referencesBasePath}
        />
      </div>
      <div id="pricing" className="hero-gradient flow-root clear-both clear-both">
        <div css={otherBg.styles} className="float-left w-full py-20 lg:py-32">
         <Pricing />
        </div>
      </div>      
      <Container>
        <ContactForm />
      </Container>
    </Layout>
  )
}

export default FrontpageTemplate

export const query = graphql`
  query {
    hero: heroYaml {
      ...HeroFields
    }
    posts: allBlogPost(sort: { fields: [date, title], order: DESC }, limit: 3) {
      nodes {
        ...BlogSectionFields
      }
    }
    portfolio: allPortfolioItem(
      sort: { fields: [publishedDate, title], order: DESC }
      limit: 3
    ) {
      nodes {
        ...PortfolioSectionFields
      }
    }
    references: allReference(
      sort: { fields: [publishedDate, name], order: DESC }
      limit: 3
    ) {
      nodes {
        ...ReferenceSectionFields
      }
    }
    services: allService {
      nodes {
        ...ServiceSectionFields
      }
    }
  }
`
