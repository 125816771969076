/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import useIsInViewport from 'use-is-in-viewport'

import React from "react";
function PricingSection() {

    const [isInViewport, targetRef] = useIsInViewport()

    return (
        <>
            <div ref={targetRef} className={isInViewport ? 'inv-visible' : 'inv-hidden'}>
                <div className="mx-auto" >
                    <div className="w-4/5 mx-auto pb-8">
                        <h2 sx={{ color: 'greenblue' }} className="text-center text-3xl mb-6">Pricing</h2>
                        <p sx={{ color: 'greenblue' }} className="text-xl mb-4 text-center text-gray-600 leading-normal">Confidential independent emotional, mental health & wellbeing support.</p>
                    </div>
                    <div className="flex flex-wrap justify-between max-w-screen-xl mx-auto">
                        <div className="w-11/12 xl:w-1/3 lg:w-1/2 sm:w-5/12 md:w-5/12 mx-auto mb-4 lg:max-w-sm shadow rounded-lg cursor-pointer border-1 border-gray-300 hover:border-blue-400 bg-white">
                            <div className="pt-10 px-8 pb-6">
                                <h4 sx={{ color: 'greenblue' }} className="text-xl text-center text-gray-800 pb-3 font-bold">Single Half-Day meeting</h4>
                                <p sx={{ color: 'greenblue' }} className="text-sm text-center text-gray-600 pb-5 leading-normal px-10">One-off Half-Day meeting</p>
                                <p className="text-base text-center text-gray-600 mt-4 font-bold">
                                    £
                                    <span sx={{ color: 'greenblue' }} className="text-4xl text-indigo-700 font-medium px-2">300</span>/ per session
                                </p>
                            </div>
                            <div className="pt-2 pr-8 pl-8">
                                <ul className="flex flex-col items-center">
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Coaching Support</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Emotional Health</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Coaching Support & Emotional Health</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Travel / Remote Options*</p>                                
                                    </li>
                                </ul>
                                <p sx={{ color: 'white' }} className="mb-6 text-center text-xs block mt-0">*To be paid in equal amounts after each visit</p>   
                                <a className="mb-10 w-full mx-auto transition-all shadow-md inline-block tracking-widest ease-linear align-middle visible whitespace-nowrap justify-center width-auto py-0 px-12 bg-green-500 text-center text-base bg py-4 font-base text-white focus:outline-none hover:opacity-90 rounded hover:bg-green-400" href="#contact">Contact Us</a>                            
                            </div>
                        </div>
                        <div className="w-11/12 xl:w-1/3 lg:w-1/2 sm:w-5/12 md:w-5/12 mx-auto mb-4 lg:max-w-sm shadow rounded-lg cursor-pointer border-1 border-gray-300 hover:border-blue-400 bg-white">
                            <div className="pt-10 px-8 pb-6">
                                <h4 sx={{ color: 'greenblue' }} className="text-xl text-center text-gray-800 pb-3 font-bold">Single 2-hour meeting per term</h4>
                                <p sx={{ color: 'greenblue' }} className="text-sm text-center text-gray-600 pb-5 leading-normal px-10">2-hour meeting per term.</p>
                                <p sx={{ color: 'greenblue' }} className="text-base text-center text-gray-600 font-bold mt-4">
                                    £
                                    <span sx={{ color: 'greenblue' }} className="text-4xl font-medium px-2">600</span>/ per annum*
                                    
                                </p>
                            </div>
                            <div className="pt-2 pr-8 pl-8">
                                <ul className="flex flex-col items-center">
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Coaching Support</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Emotional Health</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Coaching Support & Emotional Health</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Travel / Remote Options*</p>                                
                                    </li>
                                </ul>
                                <p sx={{ color: 'greenblue' }} className="mb-6 text-center text-xs block mt-0">*To be paid in equal amounts after each visit</p>   
                                <a className="mb-10 w-full mx-auto transition-all shadow-md inline-block tracking-widest ease-linear align-middle visible whitespace-nowrap justify-center width-auto py-0 px-12 bg-green-500 text-center text-base bg py-4 font-base text-white focus:outline-none hover:opacity-90 rounded hover:bg-green-400" href="#contact">Contact Us</a>
                            </div>
                        </div>
                        <div className="w-11/12 xl:w-1/3 lg:w-1/2 sm:w-5/12 md:w-5/12 mx-auto mb-4 lg:max-w-sm shadow rounded-lg cursor-pointer border-1 border-gray-300 hover:border-blue-400 bg-white">
                            <div className="pt-10 px-8 pb-6">
                                <h4 sx={{ color: 'greenblue' }} className="text-xl text-center text-gray-800 pb-3 font-bold max-w-xs">2 hour meeting per half-term</h4>
                                <p sx={{ color: 'greenblue' }} className="text-sm text-center text-gray-600 pb-5 leading-normal px-10">Book a session for every half-term</p>
                                <p sx={{ color: 'greenblue' }} className="text-base text-center text-gray-600 font-bold mt-4">
                                    £
                                    <span sx={{ color: 'greenblue' }} className="text-4xl font-medium px-2">1000</span>/ per annum*                              
                                </p>
                            </div>
                            <div className="pt-2 pr-8 pl-8">
                                <ul className="flex flex-col items-center">
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Coaching Support</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Emotional Health</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Coaching Support & Emotional Health</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <p className="text-center pl-2 text-gray-600 text-base">Travel / Remote Options*</p>                                
                                    </li>                          
                                </ul>
                                    <p sx={{ color: 'greenblue' }} className="mb-6 text-center text-xs block mt-0">*To be paid in equal amounts after each visit</p>   
                                <a className="mb-10 w-full mx-auto transition-all shadow-md inline-block tracking-widest ease-linear align-middle visible whitespace-nowrap justify-center width-auto py-0 px-12 bg-green-500 text-center text-base bg py-4 font-base text-white focus:outline-none hover:opacity-90 rounded hover:bg-green-400" href="#contact">Contact Us</a>
                            </div>
                        </div>
                        <div className="w-4/5 mx-auto pt-4">
                            <p className="text-sm text-center text-gray-600 mt-2 leading-normal">* No travel costs within a 10 mile radius, after that it is 45p per mile.</p>
                        </div>                    
                    </div>
                </div>
            </div>
        </>
    );
}

export default PricingSection;
