/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import React from 'react'

import Section from "../section"
import Service from "../service"

const ServiceSection = ({ services }) => {

  return (
    <>
      <section className="flow-root clear-both py-20 lg:py-32">
        <div className="float-left w-full lg:px-4">
          <h2 sx={{ color: 'greenblue' }} className="text-center text-3xl mb-6">What does Heads' Space offer?</h2>
            <S.Container>
            {services.map(service => (
              <Service {...service} />
            ))}
          </S.Container>
        </div>
      </section>
    </>
  )
}

export default ServiceSection

const S = {}

S.Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
